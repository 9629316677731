import React from "react"
import { Map, GoogleApiWrapper } from "google-maps-react"
import { Flex, Heading, Text, Link } from "rebass/styled-components"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LocationIcon from "../images/svgs/location"
import PhoneIcon from "../images/svgs/phone"
import EnvelopeIcon from "../images/svgs/envelope"
import ClockIcon from "../images/svgs/clock"

const ContactPage = ({ google }) => (
  <Layout noScroll>
    <SEO title="Kontakt" />
    <Map
      google={google}
      initialCenter={{
        lat: 50.0253344,
        lng: 14.5148713,
      }}
    />
    <Flex
      flexDirection="column"
      p={50}
      sx={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translateY(-50%) translateX(-50%)",
        width: "100%",
        maxWidth: 370,
        background: "white",
      }}
    >
      <Heading
        width="100%"
        mb={3}
        fontSize={6}
        color="accent"
        textAlign="center"
      >
        Kontakt
      </Heading>
      <Flex alignItems="center" mb={3}>
        <LocationIcon />
        <Link
          fontFamily="body"
          fontWeight="light"
          fontSize={1}
          lineHeight="body"
          color="text"
          ml={3}
          href="https://www.google.com/maps/dir//OC+Opatovsk%C3%A1+1753%2F12+Praha+11.NP+pinkemely/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x470b93c3be21c325:0xa9192b82484bccb?sa=X&ved=2ahUKEwjpmoTDrPPoAhVDwMQBHZ6vDEEQ9RcwE3oECBkQEQ"
          target="_blank"
        >
          OC Opatovská 1753/12 Praha 11.NP,
          <br /> žlutá terasa
        </Link>
      </Flex>

      <Flex alignItems="center" mb={3}>
        <PhoneIcon color="#31261D" height="20" />
        <Link
          fontFamily="body"
          fontWeight="light"
          fontSize={1}
          lineHeight="body"
          color="text"
          ml={3}
          href="tel:+420-777-022-416"
        >
          +420 777 022 416
        </Link>
      </Flex>

      <Flex alignItems="center" mb={3}>
        <EnvelopeIcon />
        <Link
          fontFamily="body"
          fontWeight="light"
          fontSize={1}
          lineHeight="body"
          color="text"
          ml={3}
          href="mailto:cafe@pinkemely.cz"
        >
          cafe@pinkemely.cz
        </Link>
      </Flex>

      <Flex alignItems="center" mb={3}>
        <ClockIcon color="#31261D" />
        <div>
          <Text
            as="p"
            fontFamily="body"
            fontWeight="light"
            fontSize={1}
            lineHeight="body"
            color="text"
            ml={3}
          >
            Po - Pa 09:00 - 20:00
          </Text>
          <Text
            as="p"
            fontFamily="body"
            fontWeight="light"
            fontSize={1}
            lineHeight="body"
            color="text"
            ml={3}
          >
            So - Ne 10-00 - 20:00
          </Text>
        </div>
      </Flex>
      <Text
        as="p"
        fontFamily="body"
        fontWeight="light"
        fontSize={1}
        lineHeight="body"
        color="text"
        mb={2}
      >
        Rádi Vás přivítáme kdykoli, přijďte k nám posnídat, posvačit a nebo
        ochutnat dobrou kávu sami nebo s přáteli
      </Text>
      <Text
        as="p"
        fontFamily="body"
        fontWeight="light"
        fontSize={1}
        lineHeight="body"
        color="text"
        mb={2}
      >
        Provozovatel: Pink Emely s.r.o. se sídlem Školská 689/20, Nové Město
        (Praha 1), PSČ 110 00
      </Text>
      <Text
        as="p"
        fontFamily="body"
        fontWeight="light"
        fontSize={1}
        lineHeight="body"
        color="text"
      >
        IČO: 08206406
      </Text>
    </Flex>
  </Layout>
)

export default GoogleApiWrapper({
  apiKey: "AIzaSyBY_sOw0afQnJvletfqu3fq2V8NYUDfZH8",
})(ContactPage)
